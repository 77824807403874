import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import chemistry from '../images/chemistry.png';
import WorkIcon from '@mui/icons-material/Work';
import SquareFoot from '@mui/icons-material/SquareFoot';
import Pool from '@mui/icons-material/Pool';
import School from '@mui/icons-material/School';

export default function NewTimeline() {
  return (
    <div className="flex flex-col items-center p-4 md:p-10">
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-10 text-gray-300">My Studies and Past Experiences</h1>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2015 - 2020"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<SquareFoot />}
        >
          <h3 className="vertical-timeline-element-title">Studied at L.S.S. A. Labriola</h3>
          <h4 className="vertical-timeline-element-subtitle">Ostia, Rome</h4>
          <p>
            Studied in a scientific high school with a focus on applied sciences. Left with a diploma in 2020 and a final grade of 96/100.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2020 - 2022"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<Pool />}
        >
          <h3 className="vertical-timeline-element-title">Lifeguard</h3>
          <h4 className="vertical-timeline-element-subtitle">Fregene</h4>
          <p>
            Worked for 3 consecutive summers as a lifeguard in Fregene, a seaside town near Rome.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2020 - 2024"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<School />}
        >
          <h3 className="vertical-timeline-element-title">Studied at Sapienza University of Rome</h3>
          <h4 className="vertical-timeline-element-subtitle">Rome</h4>
          <p>
            Studied Computer Science at Sapienza University of Rome. Graduated in 2024 with a final grade of 102/110.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2023 - 2024"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Frontend Developer @ HCL Software</h3>
          <h4 className="vertical-timeline-element-subtitle">Rome</h4>
          <p>
            I took part in an internship in a university setting at HCL Software as a frontend developer, where I worked on the product "Workload Automation" using mainly React.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2024 - Active"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Master Degree in Computer Science</h3>
          <h4 className="vertical-timeline-element-subtitle">Rome</h4>
          <p>
          Currently attending the Master Degree in Computer Science at Sapienza University of Rome.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      <hr className="w-full md:w-2/3 mt-10 border-gray-500" />
    </div>
  );
}
